const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy for Sealify.net</h1>
      <p>
        <strong>Last Updated:</strong> 26 April 2024
      </p>
      <p>
        We are committed to protecting your privacy and handling your data in an
        open and transparent manner. This privacy policy outlines how we handle
        the information we process and store during your use of our website.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        Sealify.net is designed to allow users to share secure notes without
        collecting personal information. Here are the details of the types of
        information we handle:
      </p>
      <ul>
        <li>
          <strong>IP Addresses:</strong> We log IP addresses for the purpose of
          maintaining the security and performance of our service. This helps us
          prevent excessive use that might slow down or abuse our service.
        </li>
        <li>
          <strong>Email Addresses:</strong> Users have the option to send note
          links via email directly through our website. We facilitate the
          sending of these emails, but we do not store email addresses or any
          other identifying information once the transmission is complete.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>
        The information we collect is used solely for the following purposes:
      </p>
      <ul>
        <li>
          <strong>To Maintain Service Integrity:</strong> We use IP addresses to
          identify and mitigate any potential misuse of our services, such as
          denial of service attacks or excessive usage that could impair service
          functionality for other users.
        </li>
        <li>
          <strong>To Facilitate Communication:</strong> When a user chooses to
          send a note link via email, we temporarily process the recipient's
          email address to complete the transmission.
        </li>
      </ul>

      <h2>3. Data Storage and Security</h2>
      <p>
        Sealify.net does not store any personal information. All notes and email
        addresses used for sending links are not retained on our servers beyond
        the necessary time required to transmit the note.
      </p>
      <p>
        In addition to standard security measures, we have implemented a secure
        workflow that includes the encryption of the note data we store. This
        ensures an added layer of protection for the confidentiality and
        integrity of your notes.
      </p>
      <p>
        We implement robust security measures to protect against unauthorized
        access, alteration, disclosure, or destruction of any data we
        temporarily process.
      </p>

      <h2>4. Your Privacy Rights</h2>
      <p>
        As we do not collect or retain personal information, there are limited
        privacy rights applicable. However, users should be aware that:
      </p>
      <ul>
        <li>
          <strong>IP Address Logging:</strong> While we do not associate IP
          addresses with individual users, IP addresses are considered personal
          information under certain regulations. We only use this information
          for the purposes stated above and retain logs only as long as
          necessary for operational and security purposes.
        </li>
      </ul>

      <h2>5. Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to update or change our Privacy Policy at any time.
        Changes to this policy will be posted on our website along with the
        updated effective date. We encourage you to periodically review this
        page for the latest information on our privacy practices.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        via privacy@sealify.net.
      </p>

      <p>
        Thank you for choosing Sealify.net to share your secure notes. We are
        committed to ensuring your experience is safe and secure.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
