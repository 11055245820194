import React, { useState } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components";

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: all 0.5s;
  height: 60%;
  @media (max-width: 720px) {
    height: 100%;
  }
`;

const TextArea = styled.textarea`
  height: 100%;
  width: 100%;
  min-height: 12rem;
  resize: none;
  border: none;
  outline: none;
  font-size: calc(10px + 1vmin);
  font-family: "Roboto Mono", monospace;
  background-color: #282c34;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  min-width: 30 rem;
  &:focus {
    border: 4px solid green;
  }
  @media (max-width: 720px) {
    width: 100%;
    font-size: 16px;
  }
`;

const LinkDisplay = styled.p`
  font-size: calc(10px + 1vmin);
  font-family: "Roboto Mono", monospace;
  color: #fff;
  background-color: #282c34;
  width: 100%;
  height: 4.5rem;
  margin: 0;
  padding: 1rem;
  text-align: center;
  border-radius: 1rem;
  &:hover {
    background-color: #4a4f5b;
  }
  animation: ${slideIn} 0.5s forwards;
  cursor: pointer;
`;

const NiceButton = styled.button`
  font-size: calc(10px + 1vmin);
  font-family: "Roboto Mono", monospace;
  color: #fff;
  background-color: green;
  width: ${"calc(12rem + 0.5vmin)"};
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: darkgreen;
  }
  @media (max-width: 720px) {
    width: 100%;
    height: 3rem;
  }
`;

const SmallButton = styled.button`
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
  color: #fff;
  background-color: green;
  width: 5rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 5rem;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: darkgreen;
  }
`;

const FloatedNotification = styled.div`
  font-size: 1.2rem;
  font-family: "Roboto Mono", monospace;
  color: green;
  background-color: white;
  border: 4px solid green;
  width: 20rem;
  padding: 1rem;
  text-align: center;
  border-radius: 1rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${fadeOut} 0.5s ease-out 0.5s forwards;
  display: none;
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282c34;
  padding: 2rem;
  border-radius: 1rem;
  width: 30rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font: 1.2rem "Roboto Mono", monospace;
  color: #fff;
  div {
    display: flex;
    gap: 4rem;
  }
`;

const StyledInput = styled.input`
  margin-top: 10px;
  padding: 10px;
  font-size: 1em;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
  // slide in with a delay
  animation: ${slideIn} 0.4s 0.2s backwards;
`;

const NoteGenerate = () => {
  const [note, setNote] = useState("");
  const [url, setUrl] = useState("");
  const [generated, setGenerated] = useState(false);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [emailNotification, setEmailNotification] = useState("");
  const [sendPressed, setSendPressed] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailButtonClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEmailNotification("");
    setSendPressed(false);
    setEmail("");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    const notification = document.querySelector(".notification");
    notification.style.display = "block";
    setTimeout(() => {
      notification.style.display = "none";
    }, 2000);
  };

  const generateNote = async (note) => {
    await axios
      .post("/api/notes", {
        text: window.btoa(note),
      })
      .then((res) => {
        setUrl(res.data.url);
        console.log(`Setting URL: ${res.data.url}`); // eslint-disable-line no-console
        const genButton = document.querySelector(".generate-button");
        const textArea = document.querySelector(".text-note");
        genButton.style.display = "none";
        textArea.disabled = true;
        setGenerated(true);
      })
      .catch((err) => {
        if (err.response && err.response.status === 429) {
          alert(
            "You are creating too many notes in a short period of time.  Please wait a few seconds before creating a new note."
          );
        } else {
          console.log(err);
        }
      });
  };

  const handleSendLinkViaEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && emailRegex.test(email)) {
      setSendPressed(true);
      setIsSending(true);
      console.log(`url before sending: ${url}`); // eslint-disable-line no-console
      setEmailNotification("Sending email...");
      await axios
        .post("/api/send", {
          emailAddress: email,
          link: url,
        })
        .then((res) => {
          setEmailNotification("Email sent!");
          setTimeout(() => {
            setEmailNotification("");
            setShowModal(false);
            setIsSending(false);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          setIsSending(false);
        });
    } else {
      setSendPressed(true);
      console.log("Invalid email address");
      setEmailNotification("Invalid Email");
    }
  };

  return (
    <AppContainer>
      <TextArea
        onChange={(e) => setNote(e.target.value)}
        value={note}
        className="text-note"
      />
      {!url ? null : (
        <LinkDisplay onClick={() => copyToClipboard()}>{url}</LinkDisplay>
      )}
      {!generated ? (
        <NiceButton
          className="generate-button"
          onClick={() => generateNote(note)}
        >
          Generate Link
        </NiceButton>
      ) : (
        <ButtonContainer>
          <NiceButton onClick={() => copyToClipboard()}>Copy</NiceButton>
          <NiceButton onClick={() => (window.location.href = "/notes")}>
            New Note
          </NiceButton>
          <NiceButton onClick={handleEmailButtonClick}>
            Send via Email
          </NiceButton>

          {showModal && (
            <StyledModal>
              <p>Enter email address:</p>
              <StyledInput
                type="email"
                value={email}
                onChange={handleEmailInput}
              />
              <div>
                <SmallButton onClick={handleModalClose}>Close</SmallButton>
                <SmallButton
                  className="send-email-button"
                  onClick={handleSendLinkViaEmail}
                  disabled={isSending}
                >
                  Send
                </SmallButton>
              </div>
              {sendPressed && <p>{emailNotification}</p>}
            </StyledModal>
          )}
        </ButtonContainer>
      )}

      <FloatedNotification className="notification">
        Copied to Clipboard!
      </FloatedNotification>
    </AppContainer>
  );
};

export default NoteGenerate;
