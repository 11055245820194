import "./App.scss";
import NoteGenerate from "./workflows/NoteGenerate";
import NoteView from "./workflows/NoteView";
import Layout from "./components/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect } from "react";

const App = () => {
  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = "hidden";

    // Enable scrolling on cleanup
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Layout>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NoteGenerate />} />
          <Route path="/notes" element={<NoteGenerate />} />
          <Route path="/notes/:id" element={<NoteView id={id} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NoteGenerate />} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
};

export default App;
