import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { keyframes } from "styled-components";

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  @media (max-width: 720px) {
    flex: 1;
  }
`;

const TextArea = styled.pre`
  margin: 0;
  flex: 1;
  width: 100%;
  min-height: 12rem;
  resize: none;
  border: none;
  outline: none;
  font-size: calc(10px + 1vmin);
  font-family: "Roboto Mono", monospace;
  background-color: #282c34;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  min-width: 30 rem;
  text-align: left;
  height: 15rem;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const NiceButton = styled.button`
  font-size: calc(10px + 1vmin);
  font-family: "Roboto Mono", monospace;
  color: #fff;
  background-color: green;
  width: ${"calc(12rem + 0.5vmin)"};
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: darkgreen;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const FloatedNotification = styled.div`
  font-size: 1.2rem;
  font-family: "Roboto Mono", monospace;
  color: green;
  background-color: white;
  border: 4px solid green;
  width: 20rem;
  padding: 1rem;
  text-align: center;
  border-radius: 1rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${fadeOut} 0.5s ease-out 0.5s forwards;
  display: none;
`;

const NoteView = (props) => {
  const [note, setNote] = useState("");
  const id = props.id;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    const notification = document.querySelector(".notification");
    notification.style.display = "block";
    setTimeout(() => {
      notification.style.display = "none";
    }, 2000);
  };

  useEffect(() => {
    if (id.length !== 32) {
      window.location.href = "/notes";
    }
    axios
      .get("/api/notes/" + id)
      .then((res) => {
        setNote(window.atob(res.data.text));
      })
      .catch((err) => {
        if (err.response.status === 404) {
          window.location.href = "/notes";
        }
      });
  }, [id]);

  return (
    <AppContainer className="App container">
      <TextArea disabled>{note}</TextArea>
      <ButtonDiv>
        <NiceButton onClick={() => (window.location.href = "/notes")}>
          New Note
        </NiceButton>
        <NiceButton onClick={() => copyToClipboard(note)}>Copy</NiceButton>
        <FloatedNotification className="notification">
          Copied to clipboard
        </FloatedNotification>
      </ButtonDiv>
    </AppContainer>
  );
};

export default NoteView;
