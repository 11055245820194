import styled from "styled-components";

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100svh;
  width: 100svw;
  gap: 1rem;
`;

const HeaderContainer = styled.header`
  background-color: #282c34;
  min-height: 10svh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2rem;
  width: 100%;
  position: fixed;
  top: 0;
`;

const HeaderContent = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s;
  @media (max-width: 720px) {
    width: 90%;
  }
`;

const FooterContainer = styled.footer`
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 0;
  min-height: 10vh;
  width: 100%;
  height: 10svh;
  transition: all 0.5s;
  @media (max-width: 720px) {
    display: none;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30svh;
  width: 60%;
  height: 100svh;
  min-width: 30svw;
  transition: all 0.5s;
  @media (max-width: 720px) {
    margin-top: 10vh;
    margin-bottom: 0;
    width: 95svw;
    height: 85svh;
  }
`;

const Logo = styled.h1`
  font-family: "Roboto Mono", monospace;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  width: 60%;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
`;

const Alink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: calc(8px + 1vmin);
  &:hover {
    color: green;
    cursor: pointer;
  }
`;

const SubTitle = styled.h6`
  font-family: "Roboto Mono", monospace;
  font-size: calc(8px + 1vmin);
  color: #fff;
  text-align: right;
  width: 100%;
  @media (max-width: 720px) {
    font-size: calc(10px + 0.5vmin);
  }
`;

const FooterMoto = styled.h6`
  font-family: "Roboto Mono", monospace;
  font-size: calc(8px + 1vmin);
  color: #fff;
  text-align: center;
  width: 100%;
  @media (max-width: 720px) {
    font-size: calc(10px + 0.5vmin);
  }
`;

const Layout = ({ children }) => {
  return (
    <AppContainer>
      <HeaderContainer>
        <HeaderContent>
          <div>
            <Logo onClick={() => (window.location.href = "/")}>Sealify</Logo>
          </div>
          <SubTitle>Send Notes Securely</SubTitle>
        </HeaderContent>
      </HeaderContainer>
      <ContentDiv>{children}</ContentDiv>
      <FooterContainer>
        <FooterMoto>&copy; Created with privacy & security in mind</FooterMoto>
        <Alink href="/privacy-policy">Privacy Policy</Alink>
      </FooterContainer>
    </AppContainer>
  );
};

export default Layout;
